/* eslint-disable no-console */

import consumer from './consumer'

consumer.subscriptions.create('NotificationChannel', {
  connected() {
    // console.log('connected to notification channel')
  },

  disconnected() {
    // console.log('disconnected to notification channel')
  },

  received(data) {
    console.log({ data })
  },
})

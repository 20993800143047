const cancellationReasons = [
  { value: 'no_aide_available', text: 'No aide available' },
  { value: 'resident_refused', text: 'Resident refused' },
  { value: 'resident_not_ready', text: 'Resident not ready' },
  { value: 'incorrect_mode_of_transport', text: 'Incorrect mode of transport requested' },
  { value: 'resident_or_family_cancelled', text: 'Resident or family cancelled' },
  { value: 'resident_feeling_sick', text: 'Resident feeling sick' },
  { value: 'error_while_creating', text: 'Error while creating' },
  { value: 'appt_canceled_by_office', text: 'Appt canceled by office' },
  { value: 'deceased_or_discharged', text: 'Deceased or discharged' },
  { value: 'other', text: 'Other' },
]

function handleButtonClick(form, applyToAllValue, modalId) {
  let el = document.getElementById('apply_to_all')

  if (!el) {
    el = document.createElement('input')
    el.type = 'hidden'
    el.id = 'apply_to_all'
    el.name = 'apply_to_all'
    form.prepend(el)
  }

  el.setAttribute('value', applyToAllValue)

  document.querySelector(`${modalId} .close_x_btn`).style.display = 'none'
  document.querySelector(`${modalId} .buttons_row`).style.display = 'none'
  document.querySelector(`${modalId} .loading`).style.display = 'block'

  // Submit the form
  form.submit()
}

const RequestControls = {
  show_modal(opts) {
    const { modalId, form, text, buttonText } = opts
    const modalElement = document.getElementById(modalId.substring(1))

    const modal = new bootstrap.Modal(modalElement, {
      backdrop: 'static',
      focus: true,
    })

    $(`${modalId} .container-content`).text(text)
    $(`${modalId} .save_btn`)
      .text(buttonText)
      .off('click')
      .on('click', function (e) {
        e.preventDefault()
        // Use .off() to prevent multiple bindings
        const f = $(modalId)
        f.find('.ajax-loader').show()
        f.find('.close_btn').hide()
        f.find('.save_btn').hide()
        f.find('.close_x_btn').hide()
        form.submit()
      })
    const classes = $(`${modalId} .save_btn`).attr('class').split(' ')
    for (let i = 0; i < classes.length; i++) {
      if (classes[i].indexOf('btn-') >= 0) {
        $(`${modalId} .save_btn`).removeClass(classes[i])
      }
    }
    $(`${modalId} .save_btn`).addClass('btn-primary')

    modal.show()
  },
  show_recurring_confirmation_modal(opts) {
    const { modalId, form } = opts
    const modalElement = document.getElementById(modalId.substring(1)) // Get modal element

    const modal = new bootstrap.Modal(modalElement, {
      backdrop: 'static',
      focus: true,
    })

    document.querySelector(`${modalId} .loading`).style.display = 'none'

    // Select all buttons inside the modal excluding the Cancel button
    const buttons = modalElement.querySelectorAll('button.btn-submit:not([data-dismiss="modal"])')

    // Add event listeners to all selected buttons
    buttons.forEach((button) => {
      button.addEventListener('click', function (e) {
        e.preventDefault()
        const allRequests = this.getAttribute('data-all-requests')

        handleButtonClick(form, allRequests, modalId)
      })
    })

    modal.show()
  },
}

const initializePage = () => {
  $('.request_controls form#complete_form input[type=submit]').on('click', function (e) {
    e.preventDefault()
    const form = $(this).closest('form')
    RequestControls.show_modal({
      modalId: '#confirmation_modal',
      form,
      text: 'Are you sure you want to complete?',
      buttonText: 'Complete',
    })
    $('#confirmation_modal .save_btn').removeClass('btn-primary')
    $('#confirmation_modal .save_btn').addClass('btn-success')
  })
  $('.request_controls form#cancel_form input[type=submit]').on('click', function (e) {
    e.preventDefault()
    const form = $(this).closest('form')
    // this needs to be removed in case the modal was previously opened and closed without submitting
    $('#cancellation_reason_wrapper').remove()
    // create the select element for the cancellation reason and wrap it in a div
    const select = $('<select>', {
      name: 'cancellation_reason',
      id: 'cancellation_reason_select',
    })
    $.each(cancellationReasons, function (index, cancellationReason) {
      select.append(
        $('<option>', {
          value: cancellationReason.value,
          text: cancellationReason.text,
        }),
      )
    })
    const label = $('<label>', {
      for: 'cancellation_reason_select',
      text: 'Select a cancellation reason:',
    })
    select.css('margin-left', '10px')
    // wrap it in a div so we can append and remove in one step
    const cancellationReasonDiv = $('<div>', {
      id: 'cancellation_reason_wrapper',
    })
    cancellationReasonDiv.append(label)
    cancellationReasonDiv.append(select)
    // END create the select element for the cancellation reason and wrap it in a div
    // set the hidden field to the first option in the select so if a user doesn't change it, it will still be submitted
    $('#cancellation_reason_field').val(cancellationReasons[0].value)
    // when the cancellation_reason select changes, update the hidden field
    select.on('change', function () {
      const selectedReason = $(this).val()
      $('#cancellation_reason_field').val(selectedReason)
    })
    if ($(this).attr('data-is-recurring') === 'yes') {
      RequestControls.show_recurring_confirmation_modal({
        modalId: '#recurring_confirmation_modal',
        form,
      })
      // add the cancellation reason div to the recurring confirmation modal
      $('#recurring_confirmation_modal .modal-body .container-fluid .row .col-12').append(
        cancellationReasonDiv,
      )
    } else {
      RequestControls.show_modal({
        modalId: '#confirmation_modal',
        form,
        text: 'Are you sure you want to cancel?',
        buttonText: 'Cancel',
      })
      $('#confirmation_modal .save_btn').removeClass('btn-primary')
      $('#confirmation_modal .save_btn').addClass('btn-danger')
      // add the cancellation reason div to the confirmation modal
      $('#confirmation_modal .modal-body .container-fluid').append(cancellationReasonDiv)
    }
  })
  $('.request_controls form#re_approve_form input[type=submit]').on(
    'click',
    function renderModal(e) {
      e.preventDefault()
      const form = $(this).closest('form')
      RequestControls.show_modal({
        modalId: '#confirmation_modal',
        form,
        text: 'Are you sure you want to re-approve this request?',
        buttonText: 'Re-Approve',
      })
    },
  )
  $('.request_controls form#re_open_form input[type=submit]').on('click', function (e) {
    e.preventDefault()
    const form = $(this).closest('form')
    RequestControls.show_modal({
      modalId: '#confirmation_modal',
      form,
      text: 'Are you sure you want to re-open this request?',
      buttonText: 'Re-Open',
    })
  })
  $('.request_controls form#approve_form input[type=submit]').on('click', function (e) {
    e.preventDefault()
    const form = $(this).closest('form')
    RequestControls.show_modal({
      modalId: '#confirmation_modal',
      form,
      text: 'Are you sure you want to approve this request?',
      buttonText: 'Approve',
    })
  })
  $('.driver_container form.start input[type=submit]').on('click', function (e) {
    e.preventDefault()
    const form = $(this).closest('form')
    RequestControls.show_modal({
      modalId: '#confirmation_modal',
      form,
      text: 'Are you sure you want to Start Transport?',
      buttonText: 'Start Transport',
    })
    $('#confirmation_modal .save_btn').removeClass('btn-primary')
    $('#confirmation_modal .save_btn').addClass('btn-success')
  })
  $('.driver_container form.stop input[type=submit]').on('click', function (e) {
    e.preventDefault()
    const form = $(this).closest('form')
    RequestControls.show_modal({
      modalId: '#confirmation_modal',
      form,
      text: 'Are you sure you want to Stop Transport?',
      buttonText: 'Stop Transport',
    })
    $('#confirmation_modal .save_btn').removeClass('btn-primary')
    $('#confirmation_modal .save_btn').addClass('btn-danger')
  })
  $('.driver_container form.arrived input[type=submit]').on('click', function (e) {
    e.preventDefault()
    const form = $(this).closest('form')
    RequestControls.show_modal({
      modalId: '#confirmation_modal',
      form,
      text: 'Are you sure you want to update as Arrived?',
      buttonText: 'Yes I Arrived at Location',
    })
    $('#confirmation_modal .save_btn').removeClass('btn-primary')
    $('#confirmation_modal .save_btn').addClass('btn-danger')
  })
}

document.addEventListener('turbo:load', initializePage)

const buildParams = (query) => {
  const qkeys = Object.keys(query)
  const params = []
  for (let i = 0; i < qkeys.length; i++) {
    const k = qkeys[i]
    const v = query[k]
    params.push(`${k}=${encodeURIComponent(v)}`)
  }
  return params.join('&')
}

const getFilteredTimeline = (query) => {
  const queryStr = buildParams(query)
  const url = $('.filters').attr('data-url')

  window.location.href = `${url}?${queryStr}`
}

const initializePage = () => {
  $('#filter_date_picker').datetimepicker({
    format: 'YYYY-MM-DD',
    collapse: true,
    allowInputToggle: true,
    timeZone: 'America/New_York',
  })

  $('.timeline_date_filter input').on('click', function handleDateFilter(e) {
    e.preventDefault()
    $(this).parent().find('.calendar-picker').click()
  })

  $('.filters button.submit_filter').on('click', function handleSubmitFilter(e) {
    e.preventDefault()
    const query = {}
    const inputElements = $('.filters input')

    for (let i = 0; i < inputElements.length; i++) {
      const el = $(inputElements[i])
      const k = el.attr('name')
      const v = el.val()
      const t = el.attr('type')

      if (v !== '') {
        if (t === 'radio') {
          if (!(k === 'status' && v === 'all') && el.prop('checked')) {
            query[k] = v
          }
        } else {
          query[k] = v
        }
      }
    }

    getFilteredTimeline(query)
  })
}

document.addEventListener('turbo:load', initializePage)

// app/javascript/packs/application.js

// Importing dependencies
import 'jquery'
import 'popper.js'
import 'jquery-ui'
import '@rails/ujs'
import '@hotwired/turbo-rails'
import 'moment'
import 'moment-timezone'
import 'bootstrap'
import 'bootstrap-select'
import './packages/jquery.slimscroll.min'
import './packages/tempusdominus-bootstrap-4'

// Set up ActionCable
import './channels/consumer'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Import your custom JavaScript files
function importAll(r) {
  r.keys().forEach(r)
}
importAll(require.context('./src', true, /\.js$/))
importAll(require.context('./channels', true, /\.js$/))

// Importing stylesheets
const stylesheetsContext = require.context('./stylesheets', true, /\.css$/)
stylesheetsContext.keys().forEach(stylesheetsContext)

require.context('./images', true)

import DispatchView from '../src/dispatch'

document.addEventListener('turbo:load', () => {
  if (document.querySelector('.dispatch_view')) {
    App.dispatch = App.cable.subscriptions.create(
      {
        channel: 'NotificationDispatch',
        current_user_id: document.querySelector('#current_user').getAttribute('data-id'),
      },
      {
        connected() {
          // Called when subscription is ready for use on the server
        },
        disconnected() {
          // console.log('Notification Dispatch, Disconnected')
        },
        received(data) {
          if (data.kind === 'update') {
            DispatchView.updateDispatchRequest(data.transport_request)
          }
        },
        send_message() {
          // console.log(opts)
        },
      },
    )
  }
})

const DispatchView = {
  updateDropComplete(el, transportRequestId, callback) {
    $.ajax({
      type: 'POST',
      url: `/transports/${
        window.location.pathname.split('/')[2]
      }/transport_requests/${transportRequestId}/update-status`,
      data: { transport_request: { complete: true } },
      success: function handleSuccess(resp) {
        callback(resp)
      },
    })
  },

  currentTestState: '',

  testUpdate: () => {
    const data = {
      appointment_time: 1537664400,
      appointment_time_formatted: 'Sep 22, 2018 - 06:00 PM',
      dispatch_state: 'arrival',
      driver: 'Carmelia Gusikowski',
      drop_off_location: 'Suite 780 2334 Vernita Pass, South Pilarmouth, NH',
      id: 70,
      patients_names: 'Jannet Mc Laughlin',
      pick_up_location: '97143 Tyler Prairie, Port Jordanport, MA',
      pick_up_time: 1537678800,
      pick_up_time_formatted: 'Sep 22, 2018 - 10:00 PM',
      request_state: 'approved',
      timer_state: 'start',
      timezone: 'Pacific Time (US & Canada)',
      timezone_js: 'America/Los_Angeles',
    }

    if (DispatchView.currentTestState === '') {
      DispatchView.currentTestState = data.dispatch_state
    } else if (DispatchView.currentTestState === 'arrival') {
      DispatchView.currentTestState = 'complete'
      data.dispatch_state = 'complete'
    }

    DispatchView.updateDispatchRequest(data)
  },

  updateDispatchRequest: (tr) => {
    const el = $('.dispatch_view').find(`.dispatch_request[data-id='${tr.id}']`)

    if (el.length > 0) {
      el.attr('data-appointment-time', tr.appointment_time)
      el.attr('data-pick-up-time', tr.pick_up_time)
      el.attr('data-timezone', tr.timezone)
      el.attr('data-timezone-js', tr.timezone_js)
      el.find('.patient').text(tr.patients_names)
      el.find('.driver').text(tr.driver)
      el.find('.origin').text(tr.pick_up_location)
      el.find('.destination').text(tr.drop_off_location)
      el.find('.pick_up_time').text(tr.pick_up_time_formatted)
      el.find('.appointment_time').text(tr.appointment_time_formatted)

      if (el.attr('data-dispatch-state') !== tr.dispatch_state) {
        const cloneEl = el.clone()
        cloneEl.attr('data-dispatch-state', tr.dispatch_state)
        cloneEl.removeClass('yellow_warning yellow_border red_warning red_border')

        const cloneHeight = el.height()

        el.css('overflow', 'hidden')
        el.animate(
          { height: '0px', opacity: 0 },
          {
            duration: 400,
            easing: 'swing',
            complete: function handleComplete() {
              el.remove()
            },
          },
        )

        cloneEl.height(0).css({ opacity: 0, overflow: 'hidden' })

        switch (tr.dispatch_state) {
          case 'notify':
            $('.notified').prepend(cloneEl)
            break
          case 'start':
            $('.started').prepend(cloneEl)
            break
          case 'arrival':
            $('.arrived').prepend(cloneEl)
            break
          case 'complete':
            $('.completed').prepend(cloneEl)
            break
          default:
            break
        }

        cloneEl.animate(
          { height: `${cloneHeight + 12}px`, opacity: 1 },
          {
            duration: 400,
            easing: 'swing',
            complete() {
              cloneEl.css({ overflow: 'visible', height: 'auto' }).removeAttr('style')
            },
          },
        )
      }
    }
  },

  documentReady() {
    $('.dispatch_view .arrived').on('mousedown', '.dispatch_request', function handleMouseDown() {
      $(this).addClass('grabbing')
    })

    $('.dispatch_view .arrived').on('mouseup', '.dispatch_request', function handleMouseUp() {
      $(this).removeClass('grabbing')
    })

    $('.dispatch_view .arrived .dispatch_request').draggable()

    $('.dispatch_view .completed').droppable({
      drop: function handleDrop(event, ui) {
        const draggableId = ui.draggable.attr('data-id')
        const el = $(`.dispatch_request[data-id='${draggableId}']`).clone()
        $(`.dispatch_request[data-id='${draggableId}']`).remove()
        el.removeClass('ui-draggable ui-draggable-handle').removeAttr('style')
        el.attr('data-dispatch-state', 'complete')
        $('.dispatch_view .completed').prepend(el)

        el.find('.spinner').show()
        DispatchView.updateDropComplete(el, draggableId, () => {
          setTimeout(() => {
            el.find('.spinner').hide()
          }, 1000)
        })
      },
    })

    $('.dispatch_view .dispatch_request').on('timer', function handleTimerEvent() {
      if ($(this).find('.time_remaining:visible').length > 0) {
        const pickUpTimeSec = parseInt($(this).attr('data-pick-up-time'), 10)
        const timezone = $(this).attr('data-timezone-js')
        const currentTimeSec = moment.tz(timezone).format('X')

        const timeRemainingSec = pickUpTimeSec - currentTimeSec
        const minutes = Math.floor(timeRemainingSec / 60)
        const seconds = timeRemainingSec % 60

        if (minutes <= 60 && minutes > 30) {
          $(this).toggleClass('yellow_warning yellow_border')
        } else if (minutes <= 0 && seconds <= 0) {
          if (!$(this).hasClass('red_warning')) {
            $(this).addClass('red_warning').removeClass('red_border')
          }
        } else if (minutes < 60 && minutes <= 30) {
          $(this).removeClass('yellow_warning yellow_border').addClass('red_warning red_border')
        } else {
          $(this).removeClass('yellow_warning yellow_border red_warning red_border')
        }

        if (minutes <= 0 && seconds <= 0) {
          $(this).find('.time_remaining').text('Time Expired, Overdue')
        } else {
          $(this)
            .find('.time_remaining')
            .text(`Remaining: ${minutes} Min, ${seconds < 10 ? `0${seconds}` : seconds} Sec`)
        }
      }
    })

    setInterval(() => {
      $('.dispatch_view .dispatch_request').trigger('timer')
    }, 1000)
  },
}

export default DispatchView

const initializePage = () => {
  if ($('.dispatch_view').length > 0) {
    DispatchView.documentReady()
  }
}

document.addEventListener('turbo:load', initializePage)

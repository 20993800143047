// Import the ActionCable library
import { createConsumer } from '@rails/actioncable'

// Create the App namespace if it doesn't exist
window.App = window.App || {}

// Set up ActionCable consumer
window.App.cable = createConsumer(
  document.getElementsByName('action-cable-url')[0]?.content || '/cable',
)

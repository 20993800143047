const submitApproveForm = (f) => {
  $.ajax({
    type: 'POST',
    url: f.attr('action'),
    data: f.serialize(),
    success() {
      $('.tab-content').toggle()
      $('.tab-content .status').toggle()
    },
  })
}

const submitNewNote = (f) => {
  if (App.notifications) {
    f.find('input[type=submit]').removeAttr('disabled')
    const noteMessage = f.find('textarea').val()

    App.notifications.send_message({
      transport_request_id: $('#conversation_id').attr('data-conversation-id'),
      note: {
        message: noteMessage,
      },
    })

    f.find('textarea').val('')
    setTimeout(function handleTimeout() {
      f.find('input[type=submit]').removeAttr('disabled')
      $('#transport_request_note').val('')
    }, 250)
  }
}

const showInsuranceInfo = (patientId, insuranceId) => {
  $.ajax({
    type: 'GET',
    url: `/patients/${patientId}/insurances/${insuranceId}`,
    success(resp) {
      $('#insurance_modal .modal-body').html(resp)
    },
  })
}

const setCoordinatorHomes = (homeIds) => {
  $.ajax({
    type: 'POST',
    url: '/coordinators/homes',
    data: { home_ids: homeIds },
    success() {
      window.location.reload()
    },
  })
}

const initializePage = () => {
  $('a.primary_insurance_info').on('click', function handlePrimaryInsuranceClick(e) {
    e.preventDefault()
    $('#insurance_modal .modal-body').html('')
    const patientId = $('label[for=transport_request_patient_id]').attr('data-patient-id')
    const insurancePatientId = $('label[for=transport_request_insurance_patient]').attr(
      'data-insurance-patient-id',
    )
    showInsuranceInfo(patientId, insurancePatientId)
    $('#insurance_modal').modal('show')
  })

  $('a.secondary_insurance_info').on('click', function handleSecondaryInsuranceClick(e) {
    e.preventDefault()
    $('#insurance_modal .modal-body').html('')
    const patientId = $('label[for=transport_request_patient_id]').attr('data-patient-id')
    const insuranceId = $('#transport_request_insurance_secondary').val()
    showInsuranceInfo(patientId, insuranceId)
    $('#insurance_modal').modal('show')
  })

  $('.tr_modal').on('click', function handleTransportModalClick() {
    $('#transport_modal .modal-body').html('')

    const href = $(this).attr('href')
    $.ajax({
      url: href,
      method: 'GET',
      dataType: 'html',
      cache: false,
      success(req) {
        $('#transport_modal .modal-body').html(req)
        $('#transport_modal').data('bs.modal').handleUpdate()
        $('#transport_modal').modal('handleUpdate')

        $('#approve_form').submit(function handleApproveFormSubmit(e) {
          e.preventDefault()
          $('.tab-content').toggle()
          submitApproveForm($('#approve_form'))
        })
      },
      complete() {},
    })
  })

  $('#transport_request_note').keypress(function handleNoteKeyPress(event) {
    if (event.which === 13) {
      if ($(this).val().length > 0) {
        $('form#new_note').submit()
      }
    }
  })

  $(document).on('submit', 'form#new_note', function handleNewNoteSubmit(evt) {
    evt.preventDefault()
    submitNewNote($('form#new_note'))
    $('#new_note').find('input[type=submit]').removeAttr('disabled')
  })

  $('[data-toggle="popover"]').popover({ html: true })

  $('.coordinator_home_selection select').on('change', function handleHomeSelectionChange() {
    setCoordinatorHomes($(this).val())
  })

  if ($('#notes').length > 0) {
    $('#notes').slimScroll({ height: '200px', start: 'bottom' })
    setTimeout(function handleNotesScroll() {
      $('#notes').slimScroll({ scrollTo: $('.notes').prop('scrollHeight') })
    }, 500)
  }
}

document.addEventListener('turbo:load', initializePage)

document.addEventListener('turbo:load', () => {
  const messagesToBottom = () => {
    $('#notes').slimScroll({ scrollTo: $('.notes').prop('scrollHeight') })
  }

  if ($('#current_user').length > 0) {
    App.notifications = App.cable.subscriptions.create(
      {
        channel: 'NotificationChannel',
        transport_request_id: $('#conversation_id').attr('data-conversation-id'),
      },
      {
        connected: () => {
          // Called when the subscription is ready for use on the server
        },

        disconnected: () => {
          // Called when the subscription has been terminated by the server
        },

        received: (data) => {
          if (data.note) {
            const { note } = data
            const messages = $('.notes')
            if (messages.length > 0) {
              const notesConversationId = `${$('.notes').attr('data-conversation-id')}`
              const transportRequestId = `${data.transport_request_id}`
              if (notesConversationId === transportRequestId) {
                messages.append(note.message)
                messagesToBottom()
              }
            }
          }
        },

        send_message: (opts) => {
          App.notifications.perform('send_message', {
            message: opts.note.message,
            transport_request_id: opts.transport_request_id,
          })
        },
      },
    )
  }

  if ($('.notes').length > 0) {
    messagesToBottom()
  }
})

const CalendarList = {
  submit: (id, params, callback) => {
    $.ajax({
      type: 'POST',
      url: `/transports/${window.location.pathname.split('/')[2]}/transport_requests/${id}/update-status`,
      cache: false,
      data: params,
      dataType: 'json',
      success: (response) => {
        callback(response)
      },
    })
  },

  reapproveTransportRequest: (event) => {
    const element = $(event)
    const transportRequestId = element.attr('data-id')
    element.hide()
    element.parent().find('img').show()
    CalendarList.submit(transportRequestId, { transport_request: { reapprove: true } }, () => {
      element.parent().trigger('change', ['approved'])
      element.parent().find('img').hide()
      element.parent().html('<span>Approved</span>')
    })
  },

  approveTransportRequest: (event) => {
    const element = $(event)
    const transportRequestId = element.attr('data-id')
    element.hide()
    element.parent().find('img').show()
    CalendarList.submit(transportRequestId, { transport_request: { approve: true } }, () => {
      element.parent().trigger('change', ['approved'])
      element.parent().find('img').hide()
      element.parent().html('<span>Approved</span>')
    })
  },

  completeTransportRequest: (event) => {
    const element = $(event)
    const transportRequestId = element.attr('data-id')
    element.hide()
    element.parent().find('img').show()
    CalendarList.submit(transportRequestId, { transport_request: { complete: true } }, () => {
      element.parent().trigger('change', ['completed'])
      element.parent().find('img').hide()
      element.parent().html('<span>Completed</span>')
    })
  },

  cancelTransportRequest: (event) => {
    const element = $(event)
    const transportRequestId = element.attr('data-id')
    element.hide()
    element.parent().find('img').show()
    CalendarList.submit(transportRequestId, { transport_request: { cancel: true } }, () => {
      element.parent().trigger('change', ['pending'])
      element.parent().find('img').hide()
      element.parent().html('<span>Canceled</span>')
    })
  },
}

let map
let directionsDisplay
let directionsService

function calculateDistance(route) {
  directionsService.route(route, (response, status) => {
    if (status === 'OK') {
      const directionsData = response.routes[0].legs[0]
      if (directionsData) {
        $('#mapcalculations')[0].innerHTML +=
          ` Driving distance is ${directionsData.distance.text} (${directionsData.duration.text}).`
      }
    }
  })
}

function calculateRoute() {
  const start = $('#mapview').attr('data-pick-up-location')
  const end = $('#mapview').attr('data-drop-off-location')
  const request = {
    origin: start,
    destination: end,
    travelMode: 'DRIVING',
  }
  directionsService.route(request, (result, status) => {
    if (status === 'OK') {
      directionsDisplay.setDirections(result)
    }
  })
  calculateDistance(request)
}

function initMap() {
  directionsService = new google.maps.DirectionsService()
  directionsDisplay = new google.maps.DirectionsRenderer()

  map = new google.maps.Map(document.getElementById('mapview'), {
    center: { lng: -77.009003, lat: 38.889931 },
    zoom: 8,
  })
  directionsDisplay.setMap(map)
  setTimeout(calculateRoute, 1500)
}

window.initMap = initMap
